


.day-selector {
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover {
  cursor: pointer;
}

.day-selector-item {
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.day-number {
  width: 100%;
  color: white;

}

.day-number-selected {
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.large-selected {
  font-weight: 900;
  text-decoration: underline;
}

.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}

.small-badge {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}

.small-badge {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

